.examList {
}

.examList .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.examList .title {
  font-size: 1.25rem;
  font-weight: bold;
}

.examList .addButton {
  padding: 0.5rem 1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.examList .editButton,
.examList .deleteButton {
  padding: 0.25rem 0.5rem;
  margin-right: 0.25rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.examList .editButton {
  background-color: #2ecc71;
  color: white;
}

.examList .deleteButton {
  background-color: #e74c3c;
  color: white;
}

.examList .tableContainer {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-x: auto;
}

.examList .tableContainer table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  font-weight: 700;
}

.examList thead th {
  background-color: #3498db;
  color: white;
  padding: 12px;
  font-weight: bolder;
}

.examList .tableContainer tr:hover {
  background-color: none;
}

.examList .tableContainer th,
.examList td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.examList .tableContainer::-webkit-scrollbar {
  width: 10px;
}

.examList .tableContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.examList tbody tr:hover {
  box-shadow: 0 0 10px rgba(243, 240, 240, 0.905);
  transition: box-shadow 0.3s ease;
}

.examList .spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.examList .loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}
.examList .pagination {
  border: none;
  color: black;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  /* width: 3%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}
.examList .pagination button {
  border: none;
}

.examList .pagination button:hover {
  background-color: #3498db;
}

.examList .pagination span {
  margin: 0 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .examList .header {
    flex-direction: column;
  }
  .examList .deleteButton,
  .examList .editButton {
    width: 100%;
  }
}

/* Form */

.addExamForm {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 43, 47, 0.1);
  z-index: 10;
  backdrop-filter: blur(0.1rem);
}
/* Styles for larger screens */
.addExamForm .modalContent {
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem #09203e;
  background-color: #09203e;
  width: 100%;
  max-width: 600px;
}

.addExamForm .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-direction: row;
}

.addExamForm .title {
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
}

.addExamForm .closeButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}

.addExamForm .formGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin-top: 0px;
}

.addExamForm .inputGroup {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.addExamForm .label {
  font-weight: bold;
  margin-bottom: 0rem;
  text-align: left;
  margin-top: 0rem;
  font-size: 1rem;
}

.addExamForm .input {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  margin-bottom: 0rem;
  background-color: white;
  color: black;
}

.addExamForm .submitButton {
  grid-column: span 2;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .addExamForm .modalContent {
    max-width: 90%;
  }

  .addExamForm .formGrid {
    grid-template-columns: minmax(0, 1fr);
    gap: 0.5rem;
  }

  .addExamForm .submitButton {
    grid-column: auto;
  }

  .addExamForm .label {
    font-weight: 300;
    font-size: 0.7rem;
  }
  .addExamForm .input {
    padding: 0.2rem;
  }
  .addExamForm .title {
    font-size: 1rem;
  }
  .addExamForm .header {
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 400px) {
  .addExamForm .modalContent {
    padding: 1rem;
  }
}
