/* exam Page */
.teacher-exam-pages {
  background-size: cover;
  padding: 20px;
  background-image: url("../../../img/bg.avif");
  height: 90vh;
  margin: 0;
}

.teacher-exam-pages h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.teacher-exam-pages .exam-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.teacher-exam-pages #optionCourseFilter {
  color: #fff;
  background-color: #ff4500;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teacher-exam-pages #optionCourseFilter:hover {
  background-color: #ff5722;
}

/* Teacher exam Card */
.teacher-exam-pages .exam-card {
  transition: transform 0.3s ease;
  /* width: 350px; */
  padding: 1rem;
  color: #333;
  border: 1px solid #bbb;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  background-clip: padding-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.teacher-exam-pages .exam-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.teacher-exam-pages .exam-card div {
  margin-bottom: 10px;
}

.teacher-exam-pages .class-attendance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teacher-exam-pages .class-attendance input[type="checkbox"] {
  margin-right: 5px;
  cursor: pointer;
}

/* Add exams Modal */

.teacher-exam-pages .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 12, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.teacher-exam-pages .modal {
  /* background-color: #fff; */
  padding: 20px;
  border-radius: 10px;
  background-color: black;
  backdrop-filter: blur(2px);
  background-clip: padding-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  /* z-index: -100; */
}

.teacher-exam-pages .form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.teacher-exam-pages .form-header h2 {
  margin: 0;
  text-align: left;
  justify-content: left;
  color: white;
}

.teacherSched .close-button button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s ease;
}

.teacher-exam-pages .close-button button:hover {
  color: #ff4500;
}

/* exam Page.css */
.teacher-exam-pages .add-exam-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 25px;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teacher-exam-pages .add-exam-btn:hover {
  background-color: #0056b3;
}

.teacher-exam-pages .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 12px;
}
.teacher-exam-pages .form-group:nth-child(4) {
  gap: 48px;
}
.teacher-exam-pages #courseFilter {
  width: 120%;
}

.teacher-exam-pages .form-group label {
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.teacher-exam-pages .form-input {
  flex: 1;
  margin-right: 10px;
  background-color: white;
}

.teacher-exam-pages .form-input:last-child {
  margin-right: 0;
}

.teacher-exam-pages .form-group > input {
  margin-bottom: 10px;
}

.teacher-exam-pages .form-group > label {
  margin-bottom: 10px;
}

.teacher-exam-pages .submit-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teacher-exam-pages .submit-btn:hover {
  background-color: #0056b3;
}

.teacher-exam-pages .spinner-container {
  display: flex;
  justify-content: center;
}

.teacher-exam-pages .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.teacher-exam-pages .form-group:nth-child(2) div {
  width: 48.5%;
}

/* teacher-exam-pages.css */

.teacher-exam-pages .filter-section {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.teacher-exam-pages .filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}

.teacher-exam-pages .filter-section label {
  /* margin-right: 10px; */
  color: #333;
  margin-top: 15px;
}

.teacher-exam-pages .filter-section select,
.teacher-exam-pages .filter-section input[type="text"] {
  /* margin-right: 10px; */
  padding: 10px;
  font-size: 1rem;
  width: 100%;
  max-width: 500px;
}

.teacher-exam-pages .filter-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teacher-exam-pages .filter-section button:hover {
  background-color: #0056b3;
}

.teacher-exam-pages .actions-teacher-exam-pages {
  display: flex;
  gap: 30px;
}
.teacher-exam-pages .actions-teacher-exam-pages button {
  background-color: #05ad05;
  border-radius: 10px;
  font-size: 15px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.teacher-exam-pages .actions-teacher-exam-pages button:hover {
  background-color: #048c04;
}

.teacher-exam-pages .icon-container {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  cursor: pointer;
}

.teacher-exam-pages .edit-icon,
.teacher-exam-pages .delete-icon {
  background-color: #ffc107;
  color: black;
  font-size: 25px;
  padding: 2px;
  cursor: pointer;
  border-radius: 10px;
}

/* 
.teacher-exam-pages .container {
  background-image: url("../../../img/bg.avif");
  height: 91vh;
  padding: 20px;
  background-size: cover;
  
} */

/* section*/
/* 
.teacher-exam-pages .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teacher-exam-pages .addButton {
  background-color: #4b5563;
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 15px;
  border-radius: 0.5rem;
}

.teacher-exam-pages .studentHeading {
  min-height: 90px;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teacher-exam-pages .headingText {
  font-size: 2rem;
  font-weight: bold;
  color: #9ba4b0;
} */

/*table section*/
/* .teacher-exam-pages .tableContainer {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-x: auto;
}

.teacher-exam-pages .tableContainer table {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
  color: white;
  font-weight: 700;
}

.teacher-exam-pages thead th {
  background-color: #4b5563;
  color: white;
  padding: 10px;
  text-align: left;
  font-weight: bold;
}

.teacher-exam-pages tbody tr:hover {
  background-color: #4b5563;
}
.teacher-exam-pages .tableContainer td {
  background-color: #525861;
}

.teacher-exam-pages th,
.teacher-exam-pages td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.teacher-exam-pages .tableContainer::-webkit-scrollbar {
  width: 10px;
}

.teacher-exam-pages .tableContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.teacher-exam-pages tbody tr:hover {
  box-shadow: 0 0 10px rgba(243, 240, 240, 0.905);
  transition: box-shadow 0.3s ease;
}

.teacher-exam-pages .editButton {
  background-color: #28a745;
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}
.teacher-exam-pages .deleteButton {
  background-color: red;
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
} */

/* form section*/
.teacher-exam-pages .form-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.713);
  padding: 20px;
  z-index: 10;
}

.teacher-exam-pages .form-content {
  padding: 2rem;
  color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 0.75rem;
  background-color: rgba(67, 75, 93, 0.3);
  backdrop-filter: blur(2px);
  background-clip: padding-box;
}

.teacher-exam-pages .form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teacher-exam-pages .title {
  margin-left: 9.5rem;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
}

.teacher-exam-pages .close-button {
  margin-top: -20px;
}

.teacher-exam-pages .form-fields {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.teacher-exam-pages .form-field {
  flex: 1;
}

.teacher-exam-pages .form-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.teacher-exam-pages .form-field input[type="text"],
.teacher-exam-pages .form-field input[type="number"],
.teacher-exam-pages .form-field input[type="date"],
.teacher-exam-pages .form-field input[type="time"],
.teacher-exam-pages .form-field input[type="file"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background-color: white;
  color: black;
}

.teacher-exam-pages .submit-button {
  width: 50%;
  background-color: #4b5563;
  color: #000;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  margin: 20px auto;
}
.teacher-exam-pages .submit-button:hover {
  background-color: #5c5d5f;
}
