.adminStudentContainer {
  padding: 10px;
}

.adminStudentContainer h1 {
  font-size: 1.875rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 1rem;
}

.adminStudentContainer .select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.adminStudentContainer .label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: black;
}

.adminStudentContainer .search-input {
  width: 100%;
  max-width: 300px;
  padding: 0.5rem 1rem;
  border: 1px solid;
  border-radius: 0.25rem;
  color: black;
}

.adminStudentContainer .select {
  width: 100%;
  max-width: 100px;
  padding: 0.5rem 1rem;
  border: 1px solid;
  border-radius: 0.25rem;
  color: black;
}

.adminStudentContainer .search-input {
  margin-top: 0.5rem;
}

@media screen and (max-width: 768px) {
  .adminStudentContainer h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .adminStudentContainer .select,
  .adminStudentContainer .search-input {
    font-size: 0.875rem;
  }

  .adminStudentContainer .select-container {
    flex-direction: column;
  }
}

.adminStudentContainer .spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminStudentContainer .loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Table COntainer */

.adminStudentContainer .table-container {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-x: auto;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.adminStudentContainer .table-container table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  color: black;
  font-weight: 700;
}

.adminStudentContainer thead th {
  background-color: #3498db;
  /* color: white; */
  padding: 12px;
  text-align: left;
  font-weight: bolder;
}

.adminStudentContainer tbody tr:hover {
  background-color: #97c1f1;
}

.adminStudentContainer th,
.adminStudentContainer td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.table-container::-webkit-scrollbar {
  width: 10px;
}

.adminStudentContainer .table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.adminStudentContainer tbody tr:hover {
  box-shadow: 0 0 10px rgba(243, 240, 240, 0.905);
  transition: box-shadow 0.3s ease;
}
.adminStudentContainer .pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.adminStudentContainer .pagination button {
  border: none;
  color: black;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  width: 3%;
}

.adminStudentContainer .pagination button:hover {
  background-color: #3498db;
}

.adminStudentContainer .pagination span {
  margin: 0 10px;
}
.adminStudentContainer .disabled-row {
  color: gray;
  text-decoration: line-through;
}
.adminStudentContainer .paidButton {
  color: white;
  background-color: #3498db;
  border-radius: 10px;
  padding: 5px;
}

.adminStudentContainer .disableButton {
  color: white;
  background-color: red;
  border-radius: 10px;
  padding: 5px;
}

.adminStudentContainer .enableButton {
  color: black;
  background-color: rgb(255, 217, 0);
  border-radius: 10px;
  padding: 5px;
}
.adminStudentContainer .deleteButton {
  color: white;
  background-color: red;
  border-radius: 10px;
  padding: 5px;
}

/* Details Modal */
.adminStudentModalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 43, 47, 0.1);
  z-index: 10;
  backdrop-filter: blur(0.1rem);
}
.adminStudentModalContainer .modalContainer {
  width: 90%;
  max-width: 600px;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem #09203e;
  background-color: #09203e;
  color: white;
}
.adminStudentModalContainer .modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adminStudentModalContainer .modalHeader h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.adminStudentModalContainer .modalContainer .closeBtn {
  border: none;
  background-color: transparent;
  color: white;
}

.adminStudentModalContainer .gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
}

.adminStudentModalContainer .gridItem {
  display: flex;
  align-items: center;
}

.adminStudentModalContainer .gridItem .icon {
  margin-right: 0.5rem;
}

.adminStudentModalContainer .gridItem .text {
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  .adminStudentModalContainer .modalContainer {
    max-width: 90%;
  }

  .adminStudentModalContainer .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  .adminStudentModalContainer .modalHeader h2 {
    font-size: 1rem;
  }

  .adminStudentModalContainer .gridItem .text {
    font-size: 0.6rem;
  }
  .adminStudentModalContainer .gridItem .icon {
    font-size: 0.6rem;
  }
}
