.sidebar {
  border: 1px solid black;
  width: 230px;
  height: 700px;
  margin: 20px;
  border-radius: 15px;
  padding: 15px;
  background-color: #3e3e45;
  color: #fff;
  position: sticky;
  z-index: 999;
}

.sidebar_header {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  text-align: center;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  align-items: center;
  font-weight: 600;
}

.sidebar_header > span > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.sidebar_contain {
  margin-top: 10px;
}

.sidebar_contain > a {
  text-decoration: none;
  color: #fff;
}

.sidebar_items {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  text-align: center;
  padding: 15px;
  font-size: 18px;
  margin-top: 5px;
  border-radius: 5px;
}

.sidebar_items:hover {
  background: #545459;
}

.sidebar_items.active {
  background: #545459;
}

.sidebar_items .sidebar_icon {
  font-size: 25px;
  display: flex;
  justify-content: center;
}

.sidebar_toggle_btn {
  width: 50px;
  height: 50px;
  margin: 20px;
  background-color: #3e3e45;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: none;
  position: absolute;
  z-index: 1000;
}

.sidebar_toggle_btn > span {
  font-size: 22px;
  display: flex;
  justify-content: center;
  color: #f3e6e6;
}

.active {
  background-color: #3e3e45;
}

.hide {
  display: none;
}
