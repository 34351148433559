.container {
  margin-top: 2rem;
}
@keyframes scroll-left{
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media (max-width:600px) {
  .container {
    margin: 0;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}
@media (min-width:600px) and (max-width:1000px) {
  .container {
    margin-bottom: 3rem;
  }
}
@media (min-width:1000px) and (max-width:1200px) {
  .container { 
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width:1200px) {
  .container {
    margin-bottom: 2rem;
    margin-bottom: 3rem;
  }
}