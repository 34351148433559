.notificationStudentContainer {
  background-size: cover;
  padding: 20px;
  background-image: url("../../../img/bg.avif");
  height: 90vh;
}

.notificationStudentContainer .headingText {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #3b3b3b;
}

.notificationStudentContainer .cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.notificationStudentContainer .card {
  transition: transform 0.3s ease;
  width: 300px;
  padding: 1.5rem;
  color: #3b3b3b;
  border: 1px solid #bbb;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  background-clip: padding-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notificationStudentContainer .card:hover {
  transform: translateY(-10px);
}

.notificationStudentContainer .card h2 {
  margin-top: 0;
  font-size: 1.3rem;
  color: #555;
}

.notificationStudentContainer .card p {
  margin-bottom: 10px;
  color: #666;
}

.notificationStudentContainer .card p:last-child {
  margin-bottom: 0;
}
