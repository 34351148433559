.student-conatiner {
  /* border: 1px solid #000; */
  margin: 20px;
}

.student-add-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student-add-btn > :last-child {
  background: #3e3e45;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(183, 182, 182);
}

.student-add-btn > :last-child:hover {
  color: #fff;
}

.student-class-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.student-class-group > div {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.student-class-card {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  padding: 15px;
}

.student-class-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid #000; */
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.student-class-title a {
  text-decoration: none;
  color: rgb(183, 182, 182);
  background-color: #000;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
}

.student-class-title a:hover {
  color: #fff;
  transition: all 0.4s ease-in-out;
  background-color: #3e3e45;
}

.student-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-top: 3px;
}

.student-count > :first-child {
  font-size: 70px;
  font-weight: 600;
}

/* Student.css */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 800px;
  position: relative;
}

.close {
  position: absolute;
  top: 25px;
  right: 20px;
  cursor: pointer;
  color: #333;
}

.close:hover {
  color: #000;
  /* Hover color */
}

.popup-content h2 {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
}

form > div {
  margin: 5px 0;
}

input[type="text"],
input[type="date"],
input[type="number"],
input[type="email"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  margin: 3px 0;
}

select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  margin: 3px 0;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.popup-content form > div {
  display: flex;
  gap: 10px;
}

.popup-content form > div > div {
  flex: 1;
}

.popup-content form label {
  width: 120px; /* Adjust as needed */
  text-align: right;
  margin-right: 10px;
}

.popup-content form input[type="text"],
.popup-content form input[type="date"],
.popup-content form input[type="number"],
.popup-content form input[type="email"],
.popup-content form select {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.popup-content form button[type="submit"] {
  padding: 10px 20px;
  background-color: #3e3e45;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  align-self: flex-end;
}
