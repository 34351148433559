.hover-effect:hover {
  background-color: #3d599b;
  cursor: pointer;
}
.marquee-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.marquee {
  display: flex;
  animation: marquee 20s linear infinite;
}

.marquee span {
  padding-right: 1000px;
  white-space: nowrap;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
/* Global container styles */
.global_container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* Optionally, add responsiveness */
@media (max-width: 768px) {
  .global_container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .global_container {
    padding: 10px;
  }
}
/* Custom Loader Styles */
.loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey background */
  border-top: 8px solid #3498db; /* Blue color for the spinning part */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

