.adminAdmission {
  padding: 20px;
}

.adminAdmission h2 {
  color: black;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.adminAdmission .spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminAdmission .loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Table COntainer */

.adminAdmission .table-container {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-x: auto;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.adminAdmission .table-container table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  color: black;
  font-weight: 700;
}

.adminAdmission thead th {
  background-color: #3498db;
  /* color: white; */
  padding: 12px;
  text-align: left;
  font-weight: bolder;
}

.adminAdmission tbody tr:hover {
  background-color: #97c1f1;
}

.adminAdmission th,
.adminAdmission td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.adminAdmission .table-container::-webkit-scrollbar {
  width: 10px;
}

.adminAdmission .table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.adminAdmission tbody tr:hover {
  box-shadow: 0 0 10px rgba(243, 240, 240, 0.905);
  transition: box-shadow 0.3s ease;
}
.adminAdmission .pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.adminAdmission .pagination button {
  border: none;
  color: black;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  width: 3%;
}

.adminAdmission .pagination button:hover {
  background-color: #3498db;
}

.adminAdmission .pagination span {
  margin: 0 10px;
}

.adminAdmission .filter-section {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adminAdmission .filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}

.adminAdmission .filter-section label {
  margin-right: 10px;
  color: black;
  margin-top: 15px;
}

.adminAdmission .filter-section select,
.adminAdmission .filter-section input[type="text"] {
  margin-right: 10px;
  padding: 10px;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .adminAdmission .Admission-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  .adminAdmission .filter-section {
    flex-direction: column;
    gap: 10px;
  }

  .adminAdmission .filter-section label {
    margin-bottom: 5px;
    margin-top: 0;
  }

  .adminAdmission .filter-section select,
  .adminAdmission .filter-section input[type="text"] {
    width: 100%;
    max-width: 300px;
    margin-right: 0;
  }
}

@media screen and (max-width: 480px) {
  .adminAdmission .Admission-card {
    padding: 1rem;
    font-size: 14px;
  }
}

/* Details Modal */
.adminAdmissionModalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 43, 47, 0.1);
  z-index: 10;
  backdrop-filter: blur(0.1rem);
}
.adminAdmissionModalContainer .modalContainer {
  width: 90%;
  max-width: 630px;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem #09203e;
  background-color: #09203e;
  color: white;
}
.adminAdmissionModalContainer .modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adminAdmissionModalContainer .modalHeader h2 {
  font-size: 2rem;
  color: white;
}
.adminAdmissionModalContainer .closeBtn {
  background-color: #09203e;
  border: 1px solid #09203e;
  margin-top: -10px;
  color: white;
}

.adminAdmissionModalContainer .gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.8rem;
}

.adminAdmissionModalContainer .gridItem {
  display: flex;
  align-items: center;
}

.adminAdmissionModalContainer .gridItem .icon {
  margin-right: 0.5rem;
}

.adminAdmissionModalContainer .gridItem .text {
  font-size: 1rem;
  color: white;
}

@media screen and (max-width: 768px) {
  .adminAdmissionModalContainer .modalContainer {
    max-width: 90%;
  }

  .adminAdmissionModalContainer .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.3rem;
  }
  .adminAdmissionModalContainer .modalHeader h2 {
    font-size: 0.7rem;
  }

  .adminAdmissionModalContainer .gridItem .text {
    font-size: 0.6rem;
  }
  .adminAdmissionModalContainer .gridItem .icon {
    font-size: 0.6rem;
  }
}
