/* .container {
  display: flex;
} */

.adminMain {
  overflow-x: hidden;
  width: 100%;
  /* max-height: 99.5vh; */
  overflow-y: auto;
  flex: 1;
}
.adminSidebarContainer {
  display: flex;
}

.adminSidebarContainer .sidebar-section {
  background: #09203e;
  color: white;
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
  position: fixed;
  margin-top: 63px;
  z-index: 1;
}

.adminSidebarContainer .top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.adminSidebarContainer .logo {
  width: 45%;
  object-fit: contain;
}

.adminSidebarContainer .bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.adminSidebarContainer .link {
  display: flex;
  color: white;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

.adminSidebarContainer .link:hover {
  background: #2e5a95;
  color: white;
  transition: all 0.5s;
}

.adminSidebarContainer .active {
  background: #3498db;
  color: white;
  border-radius: 5px;
}

.adminSidebarContainer .icon,
.adminSidebarContainer .link_text {
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .adminSidebarContainer .sidebar {
    width: 80px;
  }

  .adminSidebarContainer .bars {
    margin-left: 0;
  }

  .adminSidebarContainer .link_text {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .adminSidebarContainer .sidebar {
    width: 60px;
  }
}
