/* Syllabus Page */
.teacher-syllabus-page {
  background-size: cover;
  padding: 20px;
  background-image: url("../../../img/bg.avif");
  height: 90vh;
}

/* Add Syllabus Button */
.teacher-syllabus-page .add-syllabus-btn {
  background-color: #3e3e45;
  color: rgb(192, 190, 190);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.teacher-syllabus-page .add-syllabus-btn:hover {
  color: #fff;
}

/* Add Syllabus Form */

.teacher-syllabus-page .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 12, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.teacher-syllabus-page .modal {
  /* background-color: #fff; */
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  background-color: black;
  backdrop-filter: blur(2px);
  background-clip: padding-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  /* z-index: -100; */
}

.teacher-syllabus-page .formHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.teacher-syllabus-page .formHeader h2 {
  margin: 0;
  color: white;
}

.teacher-syllabus-page .closeButton button {
  background: white;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
}

/* SchedulePage.css */
.teacher-syllabus-page .add-schedule-btn {
  background-color: #3e3e45;
  color: rgb(192, 190, 190);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.teacher-syllabus-page .add-schedule-btn:hover {
  color: #fff;
}

.teacher-syllabus-page .form-group {
  margin-bottom: 15px;

  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  /* gap: 12px; */
}
.teacher-syllabus-page .form-group:nth-child(4) {
  gap: 48px;
}
.teacher-syllabus-page #courseFilter {
  width: 120%;
}

.teacher-syllabus-page .form-group label {
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.teacher-syllabus-page .form-input {
  flex: 1;
  margin-right: 10px;
  background-color: white;
  color: black;
}

.teacher-syllabus-page .form-input:last-child {
  margin-right: 0;
}

.teacher-syllabus-page .form-group > input {
  margin-bottom: 10px;
}

.teacher-syllabus-page .form-group > label {
  margin-bottom: 10px;
}

.teacher-syllabus-page .submit-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.teacher-syllabus-page .submit-btn:hover {
  background-color: #0056b3;
}

.teacher-syllabus-page .spinner-container {
  display: flex;
  justify-content: center;
}

.teacher-syllabus-page .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.teacher-syllabus-page .form-group:nth-child(2) div {
  width: 48.5%;
}

/* Filter Section */
.teacher-syllabus-page .filter-section {
  /* margin-bottom: 20px; */
  display: flex;
  /* width: 90%; */
  /* margin: auto; */
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid red; */
}
.teacher-syllabus-page .filter-section div {
  display: flex;
}
/* .teacher-syllabus-page .filter-section label{
  font-size: 10px;
} */
/* 
.teacher-syllabus-page .filter-select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
} */

/* Syllabus List */
.teacher-syllabus-page .syllabus-list-title {
  margin-bottom: 10px;
}
.teacher-syllabus-page .syllabus-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.teacher-syllabus-page .card {
  transition: transform 0.3s ease;
  /* width: 330px; */
  padding: 1rem;
  color: #333;
  border: 1px solid #bbb;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  background-clip: padding-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.teacher-syllabus-page .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.teacher-syllabus-page .card p {
  margin-bottom: 10px;
}
.icon-container {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  cursor: pointer;
}
.teacher-syllabus-page .edit-icon,
.teacher-syllabus-page .delete-icon {
  background-color: #ffc107;
  color: black;
  font-size: 25px;
  padding: 2px;
  cursor: pointer;
  border-radius: 10px;
}
