.heroSectionCont {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
    background: linear-gradient(135deg, #4a90e2, #50c9c3);
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    padding-top: 77px;
}
