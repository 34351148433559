/* VerticalMarquee.module.css */
.verticalMarquee {
    overflow: hidden; /* Ensures the overflow is hidden */
    height: 100px; /* Adjust this to fit one item */
    border: 1px solid #d1d5db;
    background-color: #f3f4f6;
    color: #374151;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 1.25rem;
  }
  
  .marqueeList {
    display: flex;
    flex-direction: column;
    animation: verticalScroll 10s linear infinite; /* Smooth vertical scrolling */
  }
  
  .marqueeItem {
    flex-shrink: 0;
    height: 50px; /* Adjust height to match the visible area */
    padding: 0.5rem 1rem; /* Same padding as in your original code */
    border-bottom: 1px solid #d1d5db; /* Border for separation */
  }
  
  .marqueeItem:last-child {
    border-bottom: none; /* Removes the border from the last item */
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  a:hover {
    color: #2563eb; /* Hover effect for links */
  }
  
  /* Animation Keyframes */
  @keyframes verticalScroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  