.adminChangePasswordContainer {
  padding: 1rem;
}

.adminChangePasswordContainer .formWrapper {
  width: 100%;
  border-radius: 8px;
  margin: auto;
  /* padding: 20px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.adminChangePasswordContainer .title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.adminChangePasswordContainer .link {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #3498db;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.adminChangePasswordContainer .formContainer {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  border: 1px solid #d1d5db;
  border-radius: 0.75rem;
  padding: 30px;
  background-color: #09203e;
  box-shadow: 0 4px 6px #09203e;
}

.adminChangePasswordContainer .formContainer .formTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
  /* border: 1px solid red; */
  text-align: center;
}

.adminChangePasswordContainer .form {
  display: flex;
  flex-wrap: wrap;
}

.adminChangePasswordContainer .inputGroup {
  width: 100%;
  margin-bottom: 20px;
}

.adminChangePasswordContainer .label {
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin-bottom: 8px;
}

.adminChangePasswordContainer .input {
  width: 100%;
  padding: 10px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  background-color: white;
}

.adminChangePasswordContainer .button {
  width: 100%;
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.adminChangePasswordContainer .button:hover {
  background-color: #0d47a1;
}

/* Media queries for responsiveness */
@media only screen and (max-width: 768px) {
  .adminChangePasswordContainer .formWrapper {
    width: 80%;
  }
  .adminChangePasswordContainer .formContainer .formTitle {
    font-size: 16px;
    font-weight: 200;
    text-align: left;
    /* border: 1px solid red; */
  }
  .adminChangePasswordContainer .inputGroup {
    margin-bottom: 10px;
  }
}
