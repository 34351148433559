
.dashboard_layout{
  width: calc(100% - 190px);
  margin-top: 85px;
  margin-left: 190px;
  padding: 0 20px 20px ;
}

.adminDashboardContainer .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem;
  justify-content: center;
}

.adminDashboardContainer .grid-item {
  background-color: #09203e;
  color: #7e9fca;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.adminDashboardContainer .grid-item .icon {
  margin-top: 1rem;
}

.adminDashboardContainer .grid-item .label {
  font-size: 1rem;
}

.adminDashboardContainer .grid-item .count {
  font-size: 1rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .adminDashboardContainer .grid-item {
    width: 70%;
    margin: auto;
  }
  .adminDashboardContainer .grid-item .icon {
    font-size: 40px;
  }

  .adminDashboardContainer .grid-item .label {
    margin-top: 10px;
    font-size: 0.75rem;
  }

  .adminDashboardContainer .grid-item .count {
    font-size: 0.75rem;
  }
}
