.background {
  background-size: cover;
  height: 90vh;
  background-image: url("../../assets/images/bg.jpg");
}

.studentChangePasswordCOntainer .formWrapper {
  width: 100%;
  /* border: 1px solid #ccc; */
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* margin: 20px auto; */
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: transparent; */
}

 .studentChangePasswordCOntainer .title {
  font-size: 20px;
  font-weight: 500;
}

.studentChangePasswordCOntainer .link {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #4a5568;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.studentChangePasswordCOntainer .formContainer {
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 1px solid #d1d5db;
  border-radius: 0.75rem;
  padding: 30px;
  background-color: black;
  backdrop-filter: blur(2px);
  background-clip: padding-box;
  margin-top: 30px;
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.studentChangePasswordCOntainer .formTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.studentChangePasswordCOntainer .form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.studentChangePasswordCOntainer .inputGroup {
  width: 47.9%;
  /* display: flex; */
  /* flex-direction: row; */
  margin-bottom: 20px;
  margin-left: 15px;
}
.studentChangePasswordCOntainer .inputGroup:nth-child(3) {
  width: 100%;
}

.studentChangePasswordCOntainer .label {
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin-bottom: 8px;
}

.studentChangePasswordCOntainer.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
}

.studentChangePasswordCOntainer .button {
  width: 100%;
  background-color: #2d3748;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.studentChangePasswordCOntainer .button:hover {
  background-color: #4a5568;
}
