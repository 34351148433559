/* :root {
  --background: #55021f;
  --color: white;
  --primary-color: #c31552;
} */

/* 
.adminLoginContainer a {
  text-decoration: none;
  color: white;
} */

.adminLoginContainer h1 {
  font-size: 2rem;
  margin-top: 110px;
  color: rgb(2, 86, 255);
}

.adminLoginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(244, 201, 83, 0.467);
}

.adminLoginContainer .login-container {
  position: relative;
  width: 25.2rem;
}
.adminLoginContainer .form-container {
  border: 1px solid hsla(0, 99%, 50%, 0.158);
  box-shadow: 0 0 36px 1px rgba(246, 2, 2, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(20px);
  z-index: 99;
  padding: 2rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.adminLoginContainer .login-container form input {
  display: block;
  padding: 14.5px;
  width: 100%;
  margin: 1rem 0;
  color: white;
  outline: none;
  background-color: #e2bac8;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 0.8px;
  font-size: 15px;
  backdrop-filter: blur(15px);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.adminLoginContainer .login-container form input:focus {
  box-shadow: 0 0 16px 1px rgba(253, 2, 2, 0.2);
  animation: wobble 0.3s ease-in;
  -webkit-animation: wobble 0.3s ease-in;
}

.adminLoginContainer .login-container form button {
  background-color: #c31552;
  color: white;
  display: block;
  padding: 13px;
  border-radius: 5px;
  outline: none;
  font-size: 18px;
  letter-spacing: 1.5px;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  transition: all 0.1s ease-in-out;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
}

.adminLoginContainer .login-container form button:hover {
  box-shadow: 0 0 10px 1px rgba(252, 3, 3, 0.15);
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}

.adminLoginContainer .circle {
  width: 8rem;
  height: 8rem;
  background-color: #c31552;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
}

.adminLoginContainer .illustration {
  position: absolute;
  top: -12%;
  right: 90px;
  width: 55%;
  /* object-fit: contain; */
  /* mix-blend-mode: darken; */
}

.adminLoginContainer .circle-one {
  top: 0;
  left: 0;
  z-index: -1;
  transform: translate(-45%, -45%);
  -webkit-transform: translate(-45%, -45%);
  -moz-transform: translate(-45%, -45%);
  -ms-transform: translate(-45%, -45%);
  -o-transform: translate(-45%, -45%);
}

.adminLoginContainer .circle-two {
  bottom: 0;
  right: 0;
  z-index: -1;
  transform: translate(45%, 45%);
  -webkit-transform: translate(45%, 45%);
  -moz-transform: translate(45%, 45%);
  -ms-transform: translate(45%, 45%);
  -o-transform: translate(45%, 45%);
}

.adminLoginContainer .register-forget {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}
.adminLoginContainer .opacity {
  opacity: 0.6;
}

.adminLoginContainer .theme-btn-container {
  position: absolute;
  left: 0;
  bottom: 1rem;
}

.adminLoginContainer .theme-btn {
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.adminLoginContainer .theme-btn:hover {
  width: 40px !important;
  background-color: #ecacc2;
}

@keyframes wobble {
  0% {
    transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -ms-transform: scale(1.025);
    -o-transform: scale(1.025);
  }
  25% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  75% {
    transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -ms-transform: scale(1.025);
    -o-transform: scale(1.025);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}
