.feesContainer .flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.feesContainer h1 {
  font-size: 20px;
  font-weight: bold;
}

.feesContainer .addButton {
  background-color: #3498db;
  color: #fff;
  /* font-weight: bold; */
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feesContainer .addButton:hover {
  background-color: rgb(79, 148, 244);
}

.studentHeading {
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.feesContainer .minHeader {
  padding: 1rem;
  border-radius: 0.5rem;
}

.feesContainer .headerText {
  font-size: 3rem;
  font-weight: bold;
  color: #4e4f51;
}

.feesContainer .tableContainer {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-x: auto;
}

.feesContainer .tableContainer table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  font-weight: 700;
}

.feesContainer thead th {
  background-color: #3498db;
  color: white;
  padding: 12px;
  font-weight: bolder;
}

.feesContainer .tableContainer tr:hover {
  background-color: none;
}

.feesContainer .tableContainer th,
td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.feesContainer .tableContainer::-webkit-scrollbar {
  width: 10px;
}

.feesContainer .tableContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.feesContainer tbody tr:hover {
  box-shadow: 0 0 10px rgba(243, 240, 240, 0.905);
  transition: box-shadow 0.3s ease;
}
.feesContainer .spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feesContainer .loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.feesContainer .loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.feesContainer .adminPendingButton {
  background-color: #28a745;
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.feesContainer .adminDeleteButton {
  background-color: red;
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .feesContainer .flexContainer {
    flex-direction: column;
  }
  .feesContainer h1 {
    font-size: 16px;
  }
}
