.registrationContainer {
  background-size: cover;
  background-position: center;
}

.registrationContainer .buttonContainer {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
}

.registrationContainer .button {
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  font-size: 15px;
  border: 1px solid #000;
  border-radius: 9999px;
  background-color: #fff;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  width: 80%;
}

.registrationContainer .button:hover {
  background-color: #3498db;
  color: white;
}

.registrationContainer .teacherActive {
  background-color: #3498db;
  color: white;
}

.registrationContainer .studentActive {
  background-color: #3498db;
  color: white;
}

/* Form */

.registrationFormContainer {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  border: 1px solid #6c757d;
  border-radius: 1.5rem;
  padding: 0.75rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  background-color: #09203e;
  position: relative;
}

.registrationFormContainer h1 {
  color: #3498db;
  font-size: 20px;
  text-align: center;
}

.registrationFormContainer .form {
  display: flex;
}

.registrationFormContainer .flexContainer {
  display: flex;
  gap: 1rem;
}

.registrationFormContainer .inputGroup {
  flex: 1;
}

.registrationFormContainer input {
  /* padding: 0.5rem; */
  width: 100%;
  border: 1px solid #d2d6dc;
  border-radius: 1.375rem;
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.registrationFormContainer label {
  font-size: 0.875rem;
  font-weight: 500;
  /* border: 1px solid red; */
}

.registrationFormContainer .submitButtonContainer {
  display: flex;
  justify-content: center;
}

.registrationFormContainer .submitButton {
  width: 50%;
  /* margin-left: 40%; */
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background-color: #3498db;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.registrationFormContainer .submitButton:hover {
  background-color: #4a90e2;
  color: #000;
}

.registrationFormContainer .loading {
  pointer-events: none;
}

.registrationFormContainer .spinner {
  border: 0.25rem solid #fff;
  border-top: 0.25rem solid #3498db;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 768px) {
  .registrationFormContainer {
    padding: 20px;
  }

  .registrationFormContainer h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .registrationFormContainer .form {
    flex-direction: column;
  }

  .registrationFormContainer .flexContainer {
    flex-direction: column;
    gap: 15px;
  }

  .registrationFormContainer .inputGroup {
    flex: 1;
  }

  .registrationFormContainer .submitButton {
    width: 100%;
  }
}
