/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-optical-sizing: auto;
}
body{
   font-family: 
DM Serif ;
 background-color: rgb(238, 235, 235);
}
 */
 @tailwind base;
 @tailwind components;
 @tailwind utilities;