/* Syllabus Page */
.student-syllabus-page {
  background-size: cover;
  padding: 20px;
  background-image: url("../../../img/bg.avif");
  height: 90vh;
  margin: 0;
}

/* Filter Section */
.student-syllabus-page .filter-section {
  margin-bottom: 20px;
  display: flex;
  width: 40%;
  margin: auto;
  /* border: 1px solid red; */
}

.student-syllabus-page .filter-select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Syllabus List */
.student-syllabus-page .syllabus-list-title {
  margin-bottom: 10px;
}
.student-syllabus-page .syllabus-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.student-syllabus-page .card {
  transition: transform 0.3s ease;
  width: 350px;
  padding: 1rem;
  color: #333;
  border: 1px solid #bbb;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  background-clip: padding-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  /* z-index: -1; */
}

.student-syllabus-page .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.student-syllabus-page .card p {
  margin-bottom: 10px;
}
