/* Test Page */
.teacherTest {
  background-size: cover;
  padding: 20px;
  background-image: url("../../../img/bg.avif");
  /* height: 90vh; */
  margin: 0;
}

.teacherTest h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.teacherTest .test-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.teacherTest #optionCourseFilter {
  color: #fff;
  background-color: #ff4500;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teacherTest #optionCourseFilter:hover {
  background-color: #ff5722;
}

/* Teacher Test Card */
.teacherTest .test-card {
  transition: transform 0.3s ease;
  /* width: 350px; */
  padding: 1rem;
  color: #333;
  border: 1px solid #bbb;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  background-clip: padding-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.teacherTest .test-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.teacherTest .test-card div {
  margin-bottom: 10px;
}

.teacherTest .class-attendance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teacherTest .class-attendance input[type="checkbox"] {
  margin-right: 5px;
  cursor: pointer;
}

/* Add Tests Modal */

.teacherTest .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.teacherTest .modal {
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(16, 16, 16, 0.945);
  backdrop-filter: blur(2px);
  background-clip: padding-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* display: flex; */
  flex-direction: column;
  align-items: center;
}

.teacherTest .formHeader {
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: white;
  /* margin-left: 40px; */
}

.teacherTest .formHeader h2 {
  margin: 0;
  color: white;
}

.teacherTest .closeButton button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  transition: color 0.3s ease;
  /* margin-left: 250px; */
  color: white;
}

.teacherTest .closeButton button:hover {
  color: #ff4500;
}

/* Test Page.css */
.teacherTest .add-test-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teacherTest .add-test-btn:hover {
  background-color: #0056b3;
}

.teacherTest .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 12px;
}

.teacherTest .form-group:nth-child(4) {
  gap: 48px;
}

.teacherTest #courseFilter {
  width: 120%;
}

.teacherTest .form-group label {
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.teacherTest .form-input {
  flex: 1;
  margin-right: 10px;
  background-color: white;
}

.teacherTest .form-input:last-child {
  margin-right: 0;
}

.teacherTest .form-group > input {
  margin-bottom: 10px;
}

.teacherTest .form-group > label {
  margin-bottom: 10px;
}

.teacherTest .submit-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teacherTest .submit-btn:hover {
  background-color: #0056b3;
}

.teacherTest .spinner-container {
  display: flex;
  justify-content: center;
}

.teacherTest .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.teacherTest .form-group:nth-child(2) div {
  width: 48.5%;
}

.teacherTest .form-group:nth-child(3) div~div {
  width: 48%;
}

/* TeacherTest.css */

.teacherTest .filter-section {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teacherTest .filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}

.teacherTest .filter-section label {
  color: #333;
  margin-top: 15px;
}

.teacherTest .filter-section select,
.teacherTest .filter-section input[type="text"] {
  padding: 10px;
  font-size: 1rem;
  width: 100%;
  max-width: 500px;
}

.teacherTest .filter-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teacherTest .filter-section button:hover {
  background-color: #0056b3;
}

.teacherTest .actions-teachertest {
  display: flex;
  gap: 30px;
}

.teacherTest .actions-teachertest button {
  background-color: #05ad05;
  border-radius: 10px;
  font-size: 15px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teacherTest .actions-teachertest button:hover {
  background-color: #048c04;
}

.teacherTest .icon-container {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  cursor: pointer;
}

.teacherTest .edit-icon,
.teacherTest .delete-icon {
  background-color: #ffc107;
  color: black;
  font-size: 25px;
  padding: 2px;
  cursor: pointer;
  border-radius: 10px;
}
