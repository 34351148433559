/* .container {
  display: flex;
  border: 1px solid red;
  height: 100vh;
} */

.content {
  flex: 1;
  overflow-x: hidden;
}

.navbar {
  flex-shrink: 0;
}
